.App {
  padding: 0;
  height: 100vh;
  margin: 0 auto;
  width: 100%;
  min-height: calc(100vh - 120px);
  display: grid;
  //grid-template-rows: 100px 1fr;
  //grid-template-columns: 300px 1fr;
  grid-template-rows: 260px 1fr;
  grid-template-columns: 100% 1fr;
  grid-template-areas:
    'header'
    'content'
    'footer';
  grid-row-gap: 20px;
  //grid-column-gap: 20px;
}
