.login_link {
    display: inline-block;
    padding: 15px;
    font-weight: 400;
    font-size: 18px;
    border-radius: 5px;
    background-color: #009ddf;
    box-shadow: 0 0 1px rgb(23 41 131 / 70%);
    color: #fff;
    cursor: pointer;
    transition: backcground-color 1s ease;
    text-decoration: none;
}
