.footer {
  grid-area: footer;
  height: 78px;
  width: 100%;
  background-color: #F6F7F7;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  //padding: 20px 0;

  .content {
    margin: 0 auto;
    position: relative;
    padding: 6px 10px;
    font-size: .875rem;
    color: #4a4a4a;
    font-weight: 500;
    text-decoration: none;
  }

  .nav {
    width: 60%;
    //margin-left: 20px;

    .list {
      display: flex;
      padding-left: 0;

      .item {
        list-style: none;
        margin-right: 20px;

        .link {
          text-decoration: none;

          .span {
            //font-size: .875rem;
            font-size: 16px;
            text-decoration: none;
            font-weight: bold;
            color: #008DDD;;
          }

          .span_copyright {
            font-size: 16px;
            color: #4a4a4a;
            font-weight: 0;
            text-decoration: none;
          }
        }
      }
    }
  }
}