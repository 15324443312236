.WebexMeeting {
  /*width: 500px;*/
  /*height: 500px;*/
  width: 100%;
  height: 500px;
  display: flex;
}

.webex-meeting-widget  {
  width: 100%;
}
/*.wxc-interstitial-meeting__media-container {*/
/*  max-width: 100% !important;*/
/*}*/
/*.wxc-in-meeting__media-container {*/
/*  max-width: 100% !important;*/
/*}*/