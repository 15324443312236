.header {
  grid-area: header;
  height: 260px;
  width: 100%;

  .title_block {
    height: 60px;
    width: 100%;
    background-color: #008DDD;

    .wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 60px;
      .title {
        display: flex;
        justify-content: center;
        align-content: center;
        color: #FFFFFF;
        //font-family: "Source Sans Pro", Sans-serif;
        font-size: 24px;
        font-weight: 500;
      }
    }
  }

  .logo_block {
    width: 100%;
    height: 150px;
    padding-top: 50px;

    .logo_container {
      height: 150px;
      width: 60%;
      margin-left: 20%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      .logo_wrap_de {
        //width: 150px;
        //height: 150px;
        width: 276px;
        height: 164px;
        .logo_de {
          width: 276px;
          height: 164px;
        }
      }
      .logo_wrap_fr {
        width: 248px;
        height: 164px;
        .logo_fr {
          //height: 150px;
          //width: 300px;
          width: 248px;
          height: 164px;
        }
      }

    }
  }

}



//.header {
//  grid-area: header;
//  height: 115px;
//  width: 100%;
//  margin-bottom: 20px;
//  position: fixed;
//  left: 0;
//  border-bottom: 1px solid #172983;
//
//  .nav {
//    width: 60%;
//    height: 115px;
//    margin-left: 20%;
//
//    display: flex;
//    flex-direction: row;
//    justify-content: space-between;
//    //overflow: hidden;
//
//    .link_logo {
//      margin-right: 50px;
//
//      .logo {
//        width: 115px;
//        height: 115px;
//      }
//    }
//
//    .list {
//      padding: 0;
//      width: 80%;
//      height: 115px;
//      display: flex;
//      align-items: center;
//      list-style: none;
//      margin: 0;
//      //padding-right: 200px;
//
//      .item {
//        list-style: none;
//        padding: 0;
//        float: left;
//        font-style: normal;
//        //font-weight: normal;
//        font-size: 16px;
//        line-height: 24px;
//        //text-align: center;
//        letter-spacing: 0.25px;
//        margin-right: 50px;
//        text-align: left;
//
//
//        .link {
//          text-decoration: none;
//
//          .span {
//            font-weight: 600;
//            color: #009ddf;
//            max-width: 130px;
//            cursor: pointer;
//          }
//        }
//      }
//    }
//
//    .bmfsfj_logo {
//      width: 230px;
//      height: 115px;
//    }
//  }
//
//}