.datenschutz {
  width: 50%;
  margin-left: 25%;
  color: #3D3D3D;
  padding-bottom: 50px;

  @media (max-width: 500px) {
    width: 80%;
    margin-left: 10%;
  }
  @media (min-width: 1200px) {
    width: 40%;
    margin-left: 30%;
  }

  .title_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content {
    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5em;
    }
  }
}