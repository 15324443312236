body {
    margin: 0;
    //font-family: "Montserrat";
    font-family: "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
}

code {
    font-family: 'Montserrat';
}