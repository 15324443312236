.livestream {
  grid-area: content;
  //background: #7d4705;
  width: 60%;
  margin-left: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title_wrap {
    margin-bottom: 20px;
    .title {
      margin-bottom: 25px;
      color: #312783;
      //font-size: 45px;
      font-size: 40px;
      font-weight: 600;
      text-align: center;
    }
  }

  .iframe_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;

    .iframe {
      width: 100%;
      height: 100%;
    }
  }

  .button_block {
    width: 100%;
    .button_container {
      width: 60%;
      margin-left: 20%;
      max-width: 1140px;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      .button_wrap {
        .button {
          text-decoration: none;
          .span {
            font-size: 18px;
            padding: 20px 40px;
            border-radius: 5px;
            color: #fff;
            background-color: #009DDF;
          }
        }
      }
    }
  }
}